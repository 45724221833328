<template>
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<mask id="mask0_78_677" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
			<rect width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_78_677)">
			<path
				d="M21.025 11.05V19C21.025 19.55 20.8292 20.0208 20.4375 20.4125C20.0459 20.8042 19.575 21 19.025 21H5.02503C4.47503 21 4.00419 20.8042 3.61253 20.4125C3.22086 20.0208 3.02503 19.55 3.02503 19V11.05C2.64169 10.7 2.34586 10.25 2.13753 9.7C1.92919 9.15 1.92503 8.55 2.12503 7.9L3.17503 4.5C3.30836 4.06667 3.54586 3.70833 3.88753 3.425C4.22919 3.14167 4.62503 3 5.07503 3H18.975C19.425 3 19.8167 3.1375 20.15 3.4125C20.4834 3.6875 20.725 4.05 20.875 4.5L21.925 7.9C22.125 8.55 22.1209 9.14167 21.9125 9.675C21.7042 10.2083 21.4084 10.6667 21.025 11.05ZM14.225 10C14.675 10 15.0167 9.84583 15.25 9.5375C15.4834 9.22917 15.575 8.88333 15.525 8.5L14.975 5H13.025V8.7C13.025 9.05 13.1417 9.35417 13.375 9.6125C13.6084 9.87083 13.8917 10 14.225 10ZM9.72503 10C10.1084 10 10.4209 9.87083 10.6625 9.6125C10.9042 9.35417 11.025 9.05 11.025 8.7V5H9.07503L8.52503 8.5C8.45836 8.9 8.54586 9.25 8.78753 9.55C9.02919 9.85 9.34169 10 9.72503 10ZM5.27503 10C5.57503 10 5.83753 9.89167 6.06253 9.675C6.28753 9.45833 6.42503 9.18333 6.47503 8.85L7.02503 5H5.07503L4.07503 8.35C3.97503 8.68333 4.02919 9.04167 4.23753 9.425C4.44586 9.80833 4.79169 10 5.27503 10ZM18.775 10C19.2584 10 19.6084 9.80833 19.825 9.425C20.0417 9.04167 20.0917 8.68333 19.975 8.35L18.925 5H17.025L17.575 8.85C17.625 9.18333 17.7625 9.45833 17.9875 9.675C18.2125 9.89167 18.475 10 18.775 10ZM5.02503 19H19.025V11.95C18.9417 11.9833 18.8875 12 18.8625 12H18.775C18.325 12 17.9292 11.925 17.5875 11.775C17.2459 11.625 16.9084 11.3833 16.575 11.05C16.275 11.35 15.9334 11.5833 15.55 11.75C15.1667 11.9167 14.7584 12 14.325 12C13.875 12 13.4542 11.9167 13.0625 11.75C12.6709 11.5833 12.325 11.35 12.025 11.05C11.7417 11.35 11.4125 11.5833 11.0375 11.75C10.6625 11.9167 10.2584 12 9.82503 12C9.34169 12 8.90419 11.9167 8.51253 11.75C8.12086 11.5833 7.77503 11.35 7.47503 11.05C7.12503 11.4 6.77919 11.6458 6.43753 11.7875C6.09586 11.9292 5.70836 12 5.27503 12H5.16253C5.12086 12 5.07503 11.9833 5.02503 11.95V19Z"
				fill="currentColor"
			/>
		</g>
	</svg>
</template>
